import React from 'react'

const ContactInfo = () => {
    return (
        <div className="contact-info-area ptb-100 pb-70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-md-6 offset-lg-1 offset-md-1">
                        <div className="contact-info-box">
                            <div className="back-icon">
                                <i className='bx bx-map'></i>
                            </div>
                            <div className="icon">
                                <i className='bx bx-map'></i>
                            </div>
                            <h3>Our Address</h3>
                            <p>Noroutine GmbH <br /> Berg-am-Laim Str. 64 <br /> 81673 Munich <br /> Germany</p>
                        </div>
                    </div>

                    <div className="col-lg-5 col-md-6">
                        <div className="contact-info-box">
                            <div className="back-icon">
                                <i className='bx bx-phone-call'></i>
                            </div>
                            <div className="icon">
                                <i className='bx bx-phone-call'></i>
                            </div>
                            <h3>Contact</h3>
                            <p>Hours: <strong>Mon-Fri 9:00-17:00</strong></p>
                            <p>Phone: <a href="tel:+491631669687">+49 (0) 163 166 9687</a></p>
                            <p>Fax: <a href="fax:+498941159113">+49 (0) 89 411 59 113</a></p>
                            <p>E-mail: <a href="mailto:info@noroutine.com">info@noroutine.com</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactInfo